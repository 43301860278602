













































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  BasicFilterInput,
  SubQuestion,
  QuestionsFiltersResponse,
  QuestionForm,
  CustomFilterInput,
  QuestionTypeKeyFunction,
} from '../../../types/questions.type';
import { FlexibleILOsData, HeaderILos } from '../../../types/ilos.type';
import { APPIDS, QUESION_TYPES } from '../../../enums';
import QuestionAnswersForm from '../QuestionAnswersForm.component.vue';
import QuestionMainDataForm from '../QuestionMainDataForm.component.vue';
import DialogComponent from '../../dialog.component.vue';

@Component({
  components: {
    QuestionAnswersForm,
    QuestionMainDataForm,
    DialogComponent,
  },
})
export default class CaseStudyStepTwoComponent extends Vue {
  @Prop(Boolean) readonly ilosLoading!: boolean;

  @Prop(String) readonly actionType!: string;

  @Prop(Object) readonly filtersData!: QuestionsFiltersResponse;

  @Prop(Object) readonly value!: QuestionForm;

  @Prop() readonly subjectILOS!: HeaderILos | FlexibleILOsData[];

  @Prop(Boolean) readonly isFlexibleILOs!: boolean;

  subQuestions: SubQuestion[] = this.value.subQuestions || [];

  loading = true;

  appId = APPIDS.CREATE_QUESTIONS;

  courseId = '';

  confirmDeletePanel = false;

  openPanels: number[] = [];

  questionTypeKey = QUESION_TYPES.MCQ.id || '';

  subQuestionIndexToDelete = 0;

  showAddDialog = false;

  routesActionsEnum = {
    addCaseStudy: 'addCaseStudy',
    editCaseStudy: 'editCaseStudy',
    copyCaseStudy: 'copyCaseStudy',
  };

  $refs!: {
    questionMainDataFormStepTwo: QuestionMainDataForm;
    questionAnswerFormStepTwo: QuestionAnswersForm;
    expantionPannels: HTMLFormElement;
  };

  mounted() {
    this.courseId = this.$route.params.courseId;
    this.loading = false;
    this.openAllPannels();
  }

  get questionTypes() {
    return this.filtersData.questionTypes
      ?.map((type: BasicFilterInput) => ({
        ...type,
        translatedName: this.$options.filters?.servertranslate(type.name),
        customDisabled: type.id !== QUESION_TYPES.ESSAY.id,
      }))
      .filter(
        (questionType) => ![QUESION_TYPES.ESSAY.id, QUESION_TYPES.CASE_STUDY.id, QUESION_TYPES.MATCHING.id].includes(questionType.id)
      ) as CustomFilterInput[];
  }

  handleValueChanged(value, index) {
    if (this.isFlexibleILOs) {
      this.subQuestions[index].ilos = value;
    }
  }

  lastILOschangeHandler(value: string[], index: number): void {
    this.subQuestions[index].ilosIds = value;
  }

  addSubQuestion() {
    if (this.showAddDialog && this.subQuestions.length <= 10) {
      this.subQuestions.push({
        text: '',
        answers: [],
        degree: 0,
        difficulty: 0,
        time: '00:00:00',
        typeId: this.questionTypeKey,
        ilosIds: [],
        ilos: this.isFlexibleILOs
          ? []
          : {
            '1.1.': [],
            '1.2.': [],
            '1.3.': [],
            '1.4.': [],
          },
        iloHeader: '',
        numberOfLines: this.questionTypeKey === QUESION_TYPES.ESSAY.id ? 3 : 0,
        valid: true,
        error: '',
        rubrics: [],
        questionTypeKey: (
          QUESION_TYPES[this.questionTypeKey] as QuestionTypeKeyFunction
        )().label as string,
      });

      this.openPanels.push(this.subQuestions.length - 1);
      this.showAddDialog = false;
    }
  }

  getSubQuestionTypeLabel(key) {
    return (QUESION_TYPES[key] as QuestionTypeKeyFunction)().label as string;
  }

  confrimRemoveSubQuestion(event, subQuestionIndex) {
    event.stopPropagation();
    this.confirmDeletePanel = true;
    this.subQuestionIndexToDelete = subQuestionIndex;
    this.loading = true;
  }

  removeSubQuestionPannel() {
    this.subQuestions.splice(this.subQuestionIndexToDelete, 1);
    this.loading = false;
    this.openAllPannels();
    this.confirmDeletePanel = false;
  }

  cancelDeletePanel() {
    this.loading = false;
    this.openAllPannels();
    this.confirmDeletePanel = false;
  }

  openAllPannels() {
    this.openPanels = this.subQuestions.map((subQuestion, index) => index);
  }

  validateForms() {
    let totalFormsValidation = true;
    for (let index = 0; index < this.subQuestions.length; index += 1) {
      const questionDataValidation = this.$refs.questionMainDataFormStepTwo && this.$refs.questionMainDataFormStepTwo[index].validateCaseStudyQuestion();
      const questionAnswersValidation = this.$refs.questionAnswerFormStepTwo && this.$refs.questionAnswerFormStepTwo[index].validateAnswersFrom();
      totalFormsValidation = totalFormsValidation && questionDataValidation && questionAnswersValidation;
    }
    if (!totalFormsValidation) {
      this.openAllPannels();
    }
    return totalFormsValidation;
  }
}
