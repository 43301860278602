





















































































































































































































































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import IlosComponets from '@/shared/ilos-add-component.vue';
import DurationComponent from '@/shared/duration.component.vue';
import { QUESION_TYPES } from '../../enums';
import SubjectFlexibleILOs from '../subjects/SubjectFlexibleILOs.component.vue';

import {
  QuestionForm,
  BasicFilterInput,
  QuestionsFiltersResponse,
  CustomFilterInput,
} from '../../types/questions.type';
import { Subject } from '../../types/subject.types';
import { HeaderILos, FlexibleILOs, FlexibleILOsData } from '../../types/ilos.type';
import RichTextComponent from '../../shared/richText.component.vue';

@Component({
  components: {
    DurationComponent,
    RichTextComponent,
    IlosAddEdit: IlosComponets,
    SubjectFlexibleILOs,
  },
})
export default class QuestionMainDataForm extends Vue {
  @Prop(Boolean) readonly isFlexibleILOs!: boolean;

  @Prop(String) readonly actionType!: string;

  @Prop(Boolean) readonly ilosLoading!: boolean;

  @Prop(Boolean) readonly fixedIlosHeader!: boolean;

  @Prop(String) readonly ilosHeaderId!: string;

  @Prop(String) readonly questionType!: string;

  @Prop(Object) readonly value!: QuestionForm;

  @Prop() readonly subjectILOS!: HeaderILos | FlexibleILOsData[];

  @Prop(Object) readonly filtersData!: QuestionsFiltersResponse;

  @Prop(Boolean) readonly caseStudySubQuestionAddEdit!: boolean;

  @Prop({ default: null }) readonly subject!: string;

  @Prop({
    type: Array,
    default: () => [],
  }) readonly questionTypes!: CustomFilterInput[];

  questionTypesEnum = QUESION_TYPES;

  mainDataValidation = {
    validForm: false,
    validQuestionText: !!this.value?.text,
  };

  questionTextError = '';

  selectedIlosHeader = '';

  flexibleILOs: FlexibleILOsData[] = [];

  updateFlexibleILOs(ilos: FlexibleILOs) {
    this.flexibleILOs = JSON.parse(JSON.stringify(ilos));
    this.$emit('value-changed', this.flexibleILOs);
    if (['addCaseStudy', 'editCaseStudy', 'copyCaseStudy'].includes(this.actionType)) {
      this.$emit('last-ilos-changed', this.getLastILOs(this.flexibleILOs));
    }
  }

  getLastILOs(ilos: FlexibleILOsData[], lastILOs: string[] = []): string[] {
    Object.values(ilos).forEach((ilo) => {
      if (ilo.children && Object.values(ilo.children).length > 0) {
        this.getLastILOs(Object.values(ilo.children), lastILOs);
      } else if (ilo.selected && ilo.iloId && !lastILOs.includes(ilo.iloId)) {
        lastILOs.push(ilo.iloId);
      }
    });
    return lastILOs;
  }

  rules = this.getRules();

  $refs!: {
    form: HTMLFormElement;
    questionTextEditor: RichTextComponent;
  };

  getRules() {
    return {
      title: [
        (val: string) => (!!val && !!val.trim()) || this.$t('validations.required'),
        (val: string) => val.trim().length <= 50
          || this.$t('validations.maxLength', { max: 50 }),
      ],
      degree: [
        (val: number) => val >= 0.01 || this.$t('validations.minValue', { min: 0.01 }),
        (val: number) => val <= 999 || this.$t('validations.maxValue', { max: 999 }),
        (val: string) => /^\d{0,3}(?:\.\d{1,2})?$/.test(this.parseNumber(val).toString()) || this.$t('validations.maxFloating'),

      ],
      subject: [(val: string) => !!val || this.$t('validations.required')],
      numberOfLines: [
        (val: number) => !!val || this.$t('validations.required'),
        (val: number) => val >= 3 || this.$t('validations.minValue', { min: 3 }),
        (val: number) => val <= 25 || this.$t('validations.maxValue', { max: 25 }),
        (val: number) => Number(val) === Math.round(val)
          || this.$t('validations.integerValue'),
      ],
      rubricText: [
        (val: string) => !!val || this.$t('validations.required'),
        (val: string) => val.trim().length <= 50
          || this.$t('validations.maxLength', { max: 50 }),
      ],
      rubricWeight: [
        (val: string) => !!val || this.$t('validations.required'),
        (val: number) => val >= 0.1 || this.$t('validations.minValue', { min: 0.1 }),
        (val: number) => val <= 1 || this.$t('validations.maxValue', { max: 1 }),
      ],
      questionType: [
        (val: string) => !!val || this.$t('validations.required'),
      ],
    };
  }

  // @Watch("lang", { deep: true })
  // onPropertyChanged() {
  //   debugger;
  //   this.rules = this.getRules();
  //   this.$refs.form.resetValidation();
  //   setTimeout(()=>{this.$refs.form.validate();},100)

  // }

  @Watch('value.subjectId')
  onSubjectChange(newVal) {
    // this.selectedIlosHeader = '';
    // this.$emit('ilosHeaderIdChanged', '');
    if (newVal) {
      this.fetchSubjectIlos();
    } else {
      this.resetIlos();
    }
  }

  @Watch('value.ilosHeaderId', { deep: true })
  onIloHeaderChange(newVal) {
    if (newVal) {
      this.selectedIlosHeader = newVal;
    } else {
      this.resetSelectedIlosHeader();
    }
  }

  @Watch('ilosHeaderId')
  iloHeaderChangeHandler(newVal) {
    this.onIloHeaderChange(newVal);
  }

  @Watch('subjectILOS')
  onSubjectILOsChange(): void {
    this.onSelectedIlosHeaderChange(this.selectedIlosHeader);
  }

  @Watch('selectedIlosHeader')
  onSelectedIlosHeaderChange(newIlosHeader) {
    if (this.isFlexibleILOs) {
      // const nodes = JSON.parse(JSON.stringify(this.subjectILOS));
      const nodes = this.subjectILOS as FlexibleILOsData[];
      for (let i = 0; i < nodes.length; i += 1) {
        const newNode = JSON.parse(JSON.stringify(nodes[i]));
        if (newNode.iloId === newIlosHeader) {
          if (newNode.children.length === 0) {
            newNode.selected = true;
          } else {
            this.setAllSelectedFalse(newNode);
          }
          this.flexibleILOs = Array.from([newNode as FlexibleILOsData]);
          // if (newNode.children.length === 0 && newNode.selected) {
          //   this.$emit('value-changed', this.flexibleILOs);
          // }
        }
      }
      this.$emit('value-changed', this.flexibleILOs);
    } else if (this.value && this.value.ilos) {
      Object.keys(this.value.ilos as Record<string, HeaderILos>).forEach(
        (iloHeader) => {
          if (iloHeader !== newIlosHeader && this.value.ilos) {
            this.value.ilos[iloHeader] = [];
          }
        },
      );
    }
  }

  setAllSelectedFalse(obj) {
    const newObj = obj;
    if (
      ['addCaseStudy', 'editCaseStudy', 'copyCaseStudy'].includes(
        this.actionType,
      )
      || this.actionType !== 'add'
    ) {
      newObj.selected = this.value.ilosIds?.includes(newObj.iloId);
    } else {
      newObj.selected = false; // Set the "selected" property of the new object to false
    }
    if (newObj.children) {
      newObj.children = newObj.children.map((child) => this.setAllSelectedFalse(child)); // Recursively call the function for each child
    }
    return newObj;
  }

  @Watch('mainDataValidation', { deep: true })
  onValidationChange(newVal) {
    this.$emit(
      'validationChange',
      newVal.validQuestionText && newVal.validForm,
    );
  }

  get subjectFlexibleIlosHeaders() {
    const selectedIlos = [] as BasicFilterInput[];
    const jsonIlos = JSON.parse(JSON.stringify(this.subjectILOS));
    jsonIlos.forEach((subItem) => {
      selectedIlos.push({
        id: subItem.iloId,
        name: subItem.name,
      });
    });
    return selectedIlos;
  }

  get subjectIlosHeaders() {
    return Object.keys(this.subjectILOS).map((iloHeaderId) => ({
      id: iloHeaderId,
      name: this.$options.filters?.servertranslate(
        this.subjectILOS[iloHeaderId][0].header
      ),
    })) as BasicFilterInput[];
  }

  get selectedHeaderIlos() {
    return this.selectedIlosHeader
      ? { [this.selectedIlosHeader]: this.subjectILOS[this.selectedIlosHeader] }
      : {};
  }

  get isStaticSubject() {
    return this.subject !== null;
  }

  get isEssay() {
    return this.value.typeId === QUESION_TYPES.ESSAY.id;
  }

  get isCaseStudy() {
    return this.value.typeId === QUESION_TYPES.CASE_STUDY.id;
  }

  get validateOnInit() {
    return this.actionType === 'edit';
  }

  get hasIlos() {
    return Object.keys(this.subjectILOS).length;
  }

  get filters(): QuestionsFiltersResponse {
    return {
      iloHeaders: this.filtersData.iloHeaders?.map(
        (iloHeader: BasicFilterInput) => ({
          ...iloHeader,
          translatedName: this.$options.filters?.servertranslate(
            iloHeader.name
          ),
        })
      ),
      subjects: this.filtersData?.subjects?.map((subject: Subject) => ({
        ...subject,
        id: subject.id?.toString(),
        translatedName: subject.subj_name || subject.name,
      })),
      languages: this.filtersData.languages?.map((lang: BasicFilterInput) => ({
        ...lang,
        translatedName: this.$options.filters?.servertranslate(lang.name),
      })),
    };
  }

  mounted() {
    // if (this.isFlexibleILOs && this.actionType !== 'add') {
    //   this.selectedIlosHeader = this.value.ilosHeaderId as string;
    //   this.onSelectedIlosHeaderChange(this.selectedIlosHeader);
    // }
    if (
      this.isFlexibleILOs
      || (this.actionType !== 'edit' && this.actionType !== 'copy')
    ) this.onSubjectChange(this.value.subjectId);

    if (this.fixedIlosHeader || this.ilosHeaderId) {
      this.selectedIlosHeader = this.ilosHeaderId;
    } else if ((this.value.title && !this.isCaseStudy) || (!this.value.title && this.subject !== null)) {
      this.selectedIlosHeader = this.fetchQuestionIlosHeader();
    }
    if (this.actionType === 'copy' || this.actionType === 'copyCaseStudy') {
      this.value.quesVersion = 1;
    }
  }

  parseNumber(stringVal) {
    const number = Number(stringVal);
    return Number.isNaN(number) ? 0 : number;
  }

  fetchQuestionIlosHeader() {
    return (
      Object.keys(this.value.ilos as Record<string, HeaderILos>).filter(
        (key) => this.value.ilos && this.value.ilos[key]?.length,
      )[0] || ''
    );
  }

  validateAndSetQuestionText({ content, valid, error }) {
    this.mainDataValidation.validQuestionText = valid;
    if (this.mainDataValidation.validQuestionText) {
      this.value.text = content;
    } else {
      this.questionTextError = error;
    }
  }

  addRubric() {
    return this.value.rubrics?.push({
      text: '',
      weight: 0.1,
    });
  }

  removeRubric(rubricIndex) {
    return this.value.rubrics?.splice(rubricIndex, 1);
  }

  fetchSubjectIlos() {
    this.$emit('fetchSubjectIlos', this.value.subjectId);
  }

  resetSelectedIlosHeader() {
    this.selectedIlosHeader = '';
  }

  resetIlos() {
    this.$emit('resetIlos');
  }

  validateCaseStudyQuestion() {
    const hasQuestionText = this.$refs.questionTextEditor.hasContent();
    return this.$refs.form.validate() === this.mainDataValidation.validQuestionText && hasQuestionText;
  }

  subjectChanged() {
    this.$emit('subjectChanged');
    if (!['addCaseStudy', 'editCaseStudy', 'copyCaseStudy'].includes(this.actionType)) {
      this.resetSelectedIlosHeader();
    }
  }

  ilosHeaderIdChanged() {
    this.$emit('ilosHeaderIdChanged', this.selectedIlosHeader);
  }
}
