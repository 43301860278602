








































































































































































































import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import QuestionsProvider from '../../../providers/questions.provider';
import QuestionMainDataForm from '../QuestionMainDataForm.component.vue';

import {
  BasicFilterInput,
  CaseStudyQuestionFormat,
  DetailedQuestion,
  QuestionBasicData,
  QuestionDetailsResponse,
  QuestionForm,
  QuestionsFiltersResponse,
  SubQuestion,
  TypeILO,
} from '../../../types/questions.type';
import {
  APPIDS, EXAM_GENERATION_ROUTE_NAME, QUESION_TYPES, EDIT_QUESTION_TYPE,
} from '../../../enums';
import subjectProvider from '../../../providers/subject.provider';
import { HeaderILos, FlexibleILOsData } from '../../../types/ilos.type';
import CaseStudyStepTwoComponent from './stepTwo.component.vue';
import DialogComponent from '../../dialog.component.vue';
import { Lang } from '../../../types/general.type';
import Toaster from '../../../mixins/toaster.mixin';
import { Answers } from '../../../types/exam.type';

@Component({
  components: {
    QuestionMainDataForm,
    CaseStudyStepTwoComponent,
    DialogComponent,
  },
})
export default class CaseStudyAddEditComponent extends mixins(Toaster) {
  appId = APPIDS.CREATE_QUESTIONS;

  courseId = '';

  loading = true;

  ilosLoading = false;

  showCancelConfirm = false;

  questionTypeKey = '';

  actionType = '';

  currentStep = 1;

  showSaveConfirm = false;

  showIlosHeaderIdChangeConfirm = false;

  showSubjectChangeConfirm = false;

  saveLoading = false;

  questionId = '';

  defaultSubjectId: string | null = null;

  defaultIlosHeaderId: string | undefined = '';

  addBackUrl = `/questions/${this.$route.params.courseId}/questions-list?typeId=${QUESION_TYPES.CASE_STUDY.id}&limit=10&page=1`;

  editBackUrl = `/questions/${this.$route.params.courseId}/view/${QUESION_TYPES.CASE_STUDY.id}/${this.$route.params.questionId}`;

  filtersData: QuestionsFiltersResponse = {};

  subjectILOS: FlexibleILOsData[] | HeaderILos = {};

  isFlexibleILOs = false;

  question: QuestionForm = {
    title: '',
    text: '',
    langId: '',
    training: 0,
    typeId: QUESION_TYPES.CASE_STUDY.id,
    subjectId: '',
    ilos: {
      '1.1.': [],
      '1.2.': [],
      '1.3.': [],
      '1.4.': [],
    },
    subQuestions: [],
    ilosIds: [],
  };

  actionsEnum = {
    ADD: 'addCaseStudy',
    EDIT: 'editCaseStudy',
    COPY: 'copyCaseStudy',
  };

  routesActionsEnum = {
    addCaseStudy: 'addCaseStudy',
    editCaseStudy: 'editCaseStudy',
    copyCaseStudy: 'copyCaseStudy',
  };

  $refs!: {
    questionMainDataForm: QuestionMainDataForm;
    CaseStudyStepTwoComponent: CaseStudyStepTwoComponent;
  };

  editingType: number | null = null;

  EDIT_TYPE = EDIT_QUESTION_TYPE;

  systemLanguage = null;

  isMajorChange = false;

  questionAnalyticsData: {
    subQuestionsCount: number;
    subQuestionsCorrectAnswers: {
      [subQuestionId: string]: {
        answersCount: number;
        correctAnswers: string;
      };
    };
  } = {
    subQuestionsCount: 0,
    subQuestionsCorrectAnswers: {},
  };

  updated() {
    this.systemLanguage = this.$store.state.lang.lang;
  }

  get disableSubmitBtn(): boolean {
    return this.actionType === this.actionsEnum.EDIT && this.editingType === null;
  }

  get appDrawerOpen() {
    return !this.$store.state.app.mini;
  }

  @Watch('question.subjectId', { deep: true })
  async onSubjectChange(_, oldValue) {
    this.defaultSubjectId = oldValue;
    if (this.defaultSubjectId !== oldValue) {
      this.defaultIlosHeaderId = '';
    }
  }

  async mounted() {
    this.systemLanguage = this.$store.state.lang.lang;
    this.courseId = this.$route.params.courseId;
    this.questionTypeKey = QUESION_TYPES.CASE_STUDY.label as string;
    const routeName = this.$route?.name || '';
    this.actionType = this.routesActionsEnum[routeName];
    if (this.actionType && this.actionType !== this.actionsEnum.ADD) {
      this.questionId = this.$route.params.questionId || '';
      await this.fetchQuestionData(this.questionId);
      await this.fetchSubjectIlos(this.question.subjectId, true);
    } else {
      this.fetchQuestionData();
    }
  }

  saveQuestion() {
    const questionData: CaseStudyQuestionFormat = this.formalizeReqQuestion();
    if ([this.actionsEnum.ADD, this.actionsEnum.COPY].includes(this.actionType)) {
      this.createQuestion(questionData);
    } else if (this.actionType === this.actionsEnum.EDIT) {
      this.editQuestion(questionData);
    }
  }

  async editQuestion(reqData) {
    try {
      this.saveLoading = true;
      this.loading = true;
      const { questionId, message } = await QuestionsProvider.editQuestion(
        this.appId,
        this.courseId,
        this.questionId,
        reqData,
        { questionType: this.question.typeId },
      );
      this.saveLoading = false;
      this.showSaveConfirm = false;
      this.successToaster(message as Lang, { duration: 5000 });
      this.goQuestionDetails(questionId);
    } catch (err) {
      this.saveLoading = false;
      this.showSaveConfirm = false;
      this.loading = false;
      this.errorToaster(err as Lang, { duration: 5000 });
    }
  }

  async createQuestion(reqData) {
    try {
      this.saveLoading = true;
      this.loading = true;
      const { questionId, message } = await QuestionsProvider.createQuestion(
        this.appId,
        this.courseId,
        reqData,
        { questionType: QUESION_TYPES.CASE_STUDY.id },
      );
      this.saveLoading = false;
      this.showSaveConfirm = false;
      this.successToaster(message as Lang, { duration: 5000 });
      this.goQuestionDetails(questionId);
    } catch (err) {
      this.showSaveConfirm = false;
      this.saveLoading = false;
      this.loading = false;
      this.errorToaster(err as Lang, { duration: 5000 });
    }
  }

  goQuestionDetails(questionId) {
    this.$router.push({
      name: EXAM_GENERATION_ROUTE_NAME.QUESTION_DETAILS,
      params: {
        questionId,
        questionType: this.question.typeId || '',
        courseId: this.courseId,
      },
    });
  }

  formalizeReqQuestion() {
    return {
      basicData: this.formalizeQuestionBasicData(),
      subQuestions: this.formalizeSubQuestions(),
      editingType: this.editingType,
    };
  }

  formalizeSubQuestions() {
    return this.question.subQuestions?.map((subQuestion: SubQuestion) => ({
      text: subQuestion.text,
      degree: subQuestion.degree,
      difficulty: subQuestion.difficulty,
      time: subQuestion.time,
      type: subQuestion.typeId,
      answers: this.formalizeSubQuestionAnswers(subQuestion.answers),
      ilos: this.isFlexibleILOs
        ? subQuestion.ilos as FlexibleILOsData[]
        : this.formalizeSubQuestionIlos(subQuestion.ilos),
      rubrics: subQuestion.rubrics,
      numberOfLines: subQuestion.typeId === QUESION_TYPES.ESSAY.id ? subQuestion.numberOfLines : 0,
      subQuestionId: subQuestion.subQuestionId || null,
    }));
  }

  formalizeSubQuestionAnswers(subQuestionAnswers: Answers[]) {
    return (
      subQuestionAnswers
        .map((answer) => ({
          text: answer.text,
          correct: answer.correct,
          sort: answer.sort,
          answerId: answer.answerId || null,
        }))
        .filter((ans) => ans.text) || []
    );
  }

  formalizeSubQuestionIlos(subQuestionIlos) {
    return Object.keys(subQuestionIlos as object).reduce(
      (ilosAcc: TypeILO[], key) => {
        if (subQuestionIlos) {
          const questionIlos: TypeILO[] = [];
          subQuestionIlos[key].forEach((ilo) => {
            questionIlos.push({
              iloId: ilo.iloId.toString(),
              subIloId: ilo.subIloId ? ilo.subIloId.toString() : null,
            });
          });
          return [...ilosAcc, ...questionIlos];
        }
        return ilosAcc;
      },
      [],
    );
  }

  formalizeQuestionBasicData() {
    // TODO: Add the logic needed to set value for the case study ilosHeaderId
    return {
      degree: Number(Number(this.question.degree).toFixed(2)) || 0,
      language: this.question.langId,
      subject: this.question.subjectId,
      text: this.question.text,
      title: this.question.title,
      training: this.question.training ? 1 : 0,
      type: this.question.typeId,
      ilosHeaderId: this.question.ilosHeaderId || '',
    };
  }

  async fetchQuestionData(questionId?: string) {
    try {
      const requestsArr: Promise<
        QuestionDetailsResponse | QuestionsFiltersResponse | QuestionForm
      >[] = [
        QuestionsProvider.getQuestionFilters(this.appId, this.courseId, {
          onlyActiveSubjects: true,
        }),
      ];
      if (questionId) {
        requestsArr.push(
          QuestionsProvider.getQuestionDetails(
            this.appId,
            this.courseId,
            this.questionId,
            { questionType: this.question.typeId },
            true,
          ),
        );
      } else {
        requestsArr.push(
          QuestionsProvider.getQuestionDefaultValues(this.appId, this.courseId, {
            questionType: this.question.typeId,
          }),
        );
      }
      const [filtersData, questionData] = await Promise.all(requestsArr);
      this.filtersData = filtersData as QuestionsFiltersResponse;
      if (questionId) {
        const { question }: QuestionDetailsResponse = questionData as QuestionDetailsResponse;
        this.question = this.formalizeQuestionData(question as DetailedQuestion);
        this.isFlexibleILOs = !!this.question.isFlexibleILOs;
        this.defaultSubjectId = this.question.subjectId as string;
        this.defaultIlosHeaderId = this.question.ilosHeaderId as string;
        if (question?.subject) {
          this.pushQuestionSubjectIfNotExist(question.subject);
        }
        if (this.actionType === this.actionsEnum.EDIT) {
          this.setQuestionAnalyticData();
        }
      } else {
        const defaults = questionData as QuestionForm;
        this.question.langId = defaults.langId;
        this.question.quesVersion = 1;
      }
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.errorToaster(err as Lang, { duration: 5000 });
    }
  }

  setQuestionAnalyticData() {
    if (this.actionType === this.actionsEnum.EDIT
      && this.question
      && this.question.subQuestions
      && this.question.subQuestions.length) {
      this.questionAnalyticsData.subQuestionsCount = this.question.subQuestions.length;
      this.question.subQuestions.forEach((subQuestion) => {
        if (subQuestion.subQuestionId) {
          this.questionAnalyticsData.subQuestionsCorrectAnswers[subQuestion.subQuestionId] = {
            answersCount: 0,
            correctAnswers: '',
          };
          this.questionAnalyticsData.subQuestionsCorrectAnswers[subQuestion.subQuestionId].answersCount = subQuestion.answers.length;
          this.questionAnalyticsData.subQuestionsCorrectAnswers[subQuestion.subQuestionId].correctAnswers = this.getSortedCorrectAnswers(subQuestion.answers);
        }
      });
    }
  }

  getSortedCorrectAnswers(answers: Answers[]): string {
    const correctAnswersArray: string[] = [];
    answers.forEach((answer) => {
      if (answer.correct && answer.answerId) correctAnswersArray.push(answer.answerId);
    });
    return correctAnswersArray.sort().join(',');
  }

  pushQuestionSubjectIfNotExist(subject: BasicFilterInput) {
    const alreadyExist = this.filtersData.subjects?.some(
      (subj) => subj.id?.toString() === subject.id?.toString()
    );
    if (!alreadyExist) {
      if (this.actionType === this.routesActionsEnum.editCaseStudy && this.filtersData.subjects) {
        this.filtersData.subjects.push({
          id: subject.id,
          name: subject.name as string,
        });
      } else {
        this.question.subjectId = '';
        this.defaultSubjectId = '';
        this.defaultIlosHeaderId = '';
      }
    }
  }

  formalizeQuestionData(question: DetailedQuestion) {
    const formattedQuestion = {
      title: question.basicData?.title || '',
      text: question.basicData?.text || '',
      degree: question.basicData?.degree,
      difficulty: question.basicData?.difficulty?.value || 0,
      langId: question.basicData?.language?.id || '',
      time: question.basicData?.time,
      training: question.basicData?.training || 0,
      typeId: question.basicData?.type?.id || this.question.typeId,
      subjectId: question.subject?.id?.toString() || '',
      ilosHeaderId: question.basicData?.isFlexibleILOs
        ? question.basicData?.ilosHeaderId?.toString() || ''
        : question.basicData?.ilosHeaderId?.id?.toString() || '',
      subQuestions: this.formalizeSubQuestionData(question.subQuestions as QuestionBasicData[]),
      quesVersion: question.quesVersion || 1,
      ilosIds: question.ilosIds || [],
      isFlexibleILOs: !!question.basicData?.isFlexibleILOs,
    };
    return formattedQuestion;
  }

  formalizeSubQuestionData(subQuestions: QuestionBasicData[]) {
    return subQuestions.map((subQuestion) => ({
      text: subQuestion.text || '',
      answers: subQuestion.answers || [],
      degree: subQuestion.degree,
      difficulty: subQuestion.difficulty?.value || 0,
      time: subQuestion.time,
      typeId: subQuestion.type?.id || this.question.typeId,
      ilos: subQuestion.ilos as HeaderILos,
      ilosIds: subQuestion.ilosIds,
      iloHeader: '',
      valid: true,
      error: '',
      subQuestionId: subQuestion.subQuestionId || null,
      numberOfLines: subQuestion.numberOfLines || 3,
      rubrics:
        subQuestion.rubrics?.map((rubric) => ({
          text: rubric.text || '',
          weight: (rubric.weight && Number(rubric.weight)) || 0.1,
        })) || [],
    })) as SubQuestion[];
  }

  async fetchSubjectIlos(subjectId, keepQuestionIlos = false) {
    try {
      this.ilosLoading = true;
      const { ilos, isFlexibleILOs } = await subjectProvider.getCourseIlos(this.appId, this.courseId, subjectId);
      this.ilosLoading = false;
      this.isFlexibleILOs = isFlexibleILOs;
      this.subjectILOS = isFlexibleILOs
        ? ilos as FlexibleILOsData[]
        : this.filterSubjectILOS(ilos as HeaderILos);
      if (!keepQuestionIlos) {
        this.clearQuestionIlos();
      }
    } catch (err) {
      this.ilosLoading = false;
    }
  }

  // filterSubjectFlexibleILOS(ilos: FlexibleILOsData[]): FlexibleILOsData[] {
  //   const filteredNodes = ilos.filter((node) => {
  //     const newNode = JSON.parse(JSON.stringify(node)); // create a new object instead of modifying the 'node' parameter directly
  //     if (newNode.selected || this.hasSelectedDescendants(newNode)) {
  //       newNode.children = this.filterSubjectFlexibleILOS(newNode.children);
  //       return true;
  //     }
  //     return false;
  //   });
  //   return filteredNodes;
  // }

  // hasSelectedDescendants(node: FlexibleILOs): boolean {
  // // Check if node has any selected descendants
  //   const jsonIlos2 = JSON.parse(JSON.stringify(node.children));
  //   if (jsonIlos2.some((child) => child.selected)) {
  //     return true;
  //   }
  //   // Recursively check all descendant nodes
  //   return jsonIlos2.some((child) => this.hasSelectedDescendants(child));
  // }

  filterSubjectILOS(ilos: HeaderILos) {
    return Object.keys(ilos as Record<string, HeaderILos>).reduce((acc, iloHeader) => {
      if (ilos[iloHeader]?.length) {
        return { ...acc, [iloHeader]: ilos[iloHeader] };
      }
      return acc;
    }, {});
  }

  clearQuestionIlos() {
    return this.question.subQuestions?.forEach((subQuestion, index) => {
      Object.keys(subQuestion.ilos as Record<string, HeaderILos>).forEach((key) => {
        if (this.isFlexibleILOs) {
          if (this.question.subQuestions && this.question.subQuestions[index]) {
            this.question.subQuestions[index].ilos = [];
            this.question.subQuestions[index].ilosIds = [];
            this.question.ilosHeaderId = '';
          }
        } else if (this.question.subQuestions && this.question.subQuestions[index].ilos) {
          // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
          this.question.subQuestions[index].ilos![key] = [];
        }
      });
    });
  }

  subjectChanged() {
    this.defaultIlosHeaderId = this.question.ilosHeaderId;
    return this.question.subQuestions?.forEach((subQuestion, index) => {
      Object.keys(subQuestion.ilos as Record<string, HeaderILos>).forEach((key) => {
        if (this.isFlexibleILOs) {
          if (
            this.question.subQuestions
            && this.question.subQuestions[index].ilosIds
            && (this.question.subQuestions[index].ilosIds as string[]).length > 0
          ) {
            this.showSubjectChangeConfirm = true;
          }
        } else if (this.question.subQuestions && this.question.subQuestions[index].ilos) {
          // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
          if (this.question.subQuestions[index].ilos![key].length > 0) {
            this.showSubjectChangeConfirm = true;
          }
        }
      });
    });
  }

  ilosHeaderIdChanged(newVal) {
    this.defaultIlosHeaderId = this.question.ilosHeaderId;
    this.question.ilosHeaderId = newVal;
    return this.question.subQuestions?.forEach((subQuestion, index) => {
      Object.keys(subQuestion.ilos as Record<string, HeaderILos>).forEach((key) => {
        if (this.isFlexibleILOs) {
          if (
            this.question.subQuestions
            && this.question.subQuestions[index].ilosIds
            && (this.question.subQuestions[index].ilosIds as string[]).length > 0
          ) {
            this.showIlosHeaderIdChangeConfirm = true;
          }
        } else if (this.question.subQuestions && this.question.subQuestions[index].ilos) {
          // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
          if (this.question.subQuestions[index].ilos![key].length > 0) {
            this.showIlosHeaderIdChangeConfirm = true;
          }
        }
      });
    });
  }

  async confirmChangeIlosHeaderId() {
    this.showIlosHeaderIdChangeConfirm = false;
    const header = this.question.ilosHeaderId;
    this.clearQuestionIlos();
    this.question.ilosHeaderId = header;
  }

  cancelChangeIlosHeaderId() {
    if (this.defaultIlosHeaderId) {
      this.question.ilosHeaderId = this.defaultIlosHeaderId as string;
    }
    this.showIlosHeaderIdChangeConfirm = false;
  }

  confirmChangeSubject() {
    this.showSubjectChangeConfirm = false;
    this.defaultIlosHeaderId = '';
    this.clearQuestionIlos();
  }

  cancelChangeSubject() {
    this.question.subjectId = this.defaultSubjectId as string;
    this.question.ilosHeaderId = this.defaultIlosHeaderId as string;
    this.showSubjectChangeConfirm = false;
  }

  async validateMainQuestionFormStep() {
    if (this.$refs.questionMainDataForm.validateCaseStudyQuestion()) {
      if (!this.isFlexibleILOs) await this.fetchSubjectIlos(this.question.subjectId, true);
      this.currentStep = 2;
    }
  }

  validateFormShowConfirm() {
    if (this.$refs.CaseStudyStepTwoComponent.validateForms()) {
      this.editingType = null;
      if (this.actionType === this.actionsEnum.EDIT) {
        this.isMajorChange = this.checkIsMajorChange();
      }
      this.showSaveConfirm = true;
    }
  }

  checkIsMajorChange(): boolean {
    this.isMajorChange = false;
    if (this.question?.subQuestions?.length !== this.questionAnalyticsData.subQuestionsCount) {
      return true;
    }
    for (let subQuestionIndex = 0; subQuestionIndex < this.questionAnalyticsData.subQuestionsCount; subQuestionIndex += 1) {
      const subQuestion = this.question.subQuestions[subQuestionIndex];
      if (!(subQuestion.subQuestionId && this.questionAnalyticsData.subQuestionsCorrectAnswers[subQuestion.subQuestionId] !== undefined)) {
        return true;
      }
      if (!(subQuestion.answers.length === this.questionAnalyticsData.subQuestionsCorrectAnswers[subQuestion.subQuestionId].answersCount
        && this.getSortedCorrectAnswers(subQuestion.answers) === this.questionAnalyticsData.subQuestionsCorrectAnswers[subQuestion.subQuestionId].correctAnswers)) {
        return true;
      }
      for (let answerIndex = 0; answerIndex < this.questionAnalyticsData.subQuestionsCorrectAnswers[subQuestion.subQuestionId].answersCount; answerIndex += 1) {
        const answer: Answers = subQuestion.answers[answerIndex];
        if (!answer.answerId) {
          return true;
        }
      }
    }
    return false;
  }

  goBack() {
    if (this.actionType && this.actionType !== this.actionsEnum.ADD) {
      this.$router.push({ path: this.editBackUrl });
    } else {
      this.$router.push({ path: this.addBackUrl });
    }
  }

  handleValueChanged(value) {
    this.question.ilos = value;
  }
}
